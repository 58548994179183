import React from "react";
import Uploader from "./Uploader";

export default function App() {
    return (
        <div>
            <Uploader />
        </div>
    );
}
